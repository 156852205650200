import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Note from "../../../../../components/note"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import SyllablesEvaluation from "../../../../../components/syllables-evaluation"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"
import AudioPlayer from "../../../../../components/audio-player"
import Text from "../../../../../components/text"

const Page = () => {
  const { getAnswer, getTask, getStatsForTaskWithDict } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })
  const taskProps = {
    courseId: "hoelderlins-reisen",
    chapterId: "02-reiseerinnerung",
    taskId: "silben",
  }
  const task = getTask(taskProps)
  const answer = getAnswer(taskProps)
  const statistics = getStatsForTaskWithDict(taskProps)

  const RenderAnswer = ({ id, children }) => {
    return (
      <SyllablesEvaluation
        answer={answer?.[id]}
        solution={task.solution[id]}
        key={`answer_${id}`}
      >
        {children}
      </SyllablesEvaluation>
    )
  }

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/hoelderlins-reisen/02-reiseerinnerung/eigener-gedichtvortrag"
        />
      }
    >
      <Seo title="Das Spiel mit den Silben" />
      <Stack>
        <FeedbackHeaderProgress
          correct={statistics.correct}
          total={statistics.correct + statistics.incorrect + statistics.missed}
        />
        <Stack>
          <Stack space={3}>
            <RenderAnswer id="q-01">
              Lange lieb ich dich schon, möchte dich, mir zur Lust,
            </RenderAnswer>
            <RenderAnswer id="q-02">
              Mutter nennen und dir schenken ein kunstlos Lied,
            </RenderAnswer>
            <RenderAnswer id="q-03">Du der Vaterlandsstädte</RenderAnswer>
            <RenderAnswer id="q-04">
              Ländlichschönste, so viel ich sah.
            </RenderAnswer>
          </Stack>
          <Stack space={3}>
            <RenderAnswer id="q-05">
              Wie der Vogel des Walds über die Gipfel fliegt,
            </RenderAnswer>
            <RenderAnswer id="q-06">
              Schwingt sich über den Strom, wo er vorbei dir glänzt
            </RenderAnswer>
            <RenderAnswer id="q-07">Leicht und kräftig die Brücke</RenderAnswer>
            <RenderAnswer id="q-08">
              Die von Wagen und Menschen tönt.
            </RenderAnswer>
          </Stack>
        </Stack>
        <Note title="Ein ganz regelmäßiges auf und ab">
          <Text size={[2, 3]}>
            Hölderlin muss beim Schreiben genau darauf geachtet haben, wieviele
            Silben in einer Zeile stehen. Er hat beim Schreiben also
            mitgerechnet und das Gedicht so aufgebaut, dass sich ein
            regelmäßiges Muster ergibt. So bekommt das Gedicht einen ganz
            bestimmten Rhythmus. Höre es dir einmal an und achte darauf!
          </Text>
        </Note>
        <AudioPlayer src={`kurse/heidelberg`} />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
